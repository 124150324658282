<template>
    <div>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            color="primary"
            class="mx-1"
            v-on="on"
            v-bind="attrs"
            @click="removeItem"
          >
            mdi-close
          </v-icon>
        </template>
        <span>Remove</span>
      </v-tooltip>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      removeItem() {
        this.params.context.parentComponent.removeCostCharges(this.params.rowIndex);
      },
    },
  };
  </script>
  
  